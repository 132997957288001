<template>
  <div class="home-page">
    <div class="content-wrap">
      论坛维护中，暂不开放！
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  components: {},
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_var.scss";
.home-page {
  min-height: 100vh;
  background-color: #f2f2f2;
  display: flex;
  align-items: center;
  justify-content: center;
  .content-wrap {
    padding: 50px 40px;
    border-radius: 8px;
    font-size: 20px;
    text-align: center;
    background-color: #fff;
  }
}
</style>
